import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faStopwatch } from '@fortawesome/free-solid-svg-icons';

import { BlogQuery } from '../../types/graphql-types';
import BaseLayout from '../layouts/base';
import { BlogPostContext } from '../lib/createPages';
import { rhythm } from '../utils/typography';
import Bio from '../components/bio';
import { useTablet } from '../utils/useMediaQuery';

const Title = styled.div`
  padding: ${rhythm(1 / 2)};
  * {
    margin: 0;
  }
  p {
    diaplay: inline-block;
    font-size: ${rhythm(1 / 2)};
  }
`;

const PageNavigator = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;

  a {
    flex-grow: 1;
  }
  a:last-child {
    text-align: end;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;

  article {
    display: flex;
    min-width: 0;
    flex-basis: 100%;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 ${rhythm(1 / 2)};
  }
  div.sidebar {
    flex-grow: 0;
    position: sticky;
    top: ${rhythm(1)};
    width: 100%;
    padding: 0 ${rhythm(1 / 2)};
  }
`;

const BlogTemplate: React.FC<PageProps<BlogQuery, BlogPostContext>> = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;
  const isTablet = useTablet();

  return (
    <BaseLayout title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt}>
      <Title>
        <h1>
          <strong>{post.frontmatter.title}</strong>
        </h1>
        <p>
          <FontAwesomeIcon icon={faCalendarDay} /> {post.frontmatter.date}
          ・
          <FontAwesomeIcon icon={faStopwatch} /> {post.timeToRead} min read
        </p>
      </Title>
      <ContentContainer>
        <article>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
        {!isTablet && post.tableOfContents && (
          <div>
            <div className="sidebar" dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />
          </div>
        )}
      </ContentContainer>
      <hr />
      <Bio />
      <PageNavigator>
        {previous && (
          <Link to={previous.fields.slug} rel="prev">
            ← {previous.frontmatter.title}
          </Link>
        )}
        <div />
        {next && (
          <Link to={next.fields.slug} rel="next">
            {next.frontmatter.title} →
          </Link>
        )}
      </PageNavigator>
    </BaseLayout>
  );
};

export default BlogTemplate;

export const pageQuery = graphql`
  query Blog($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
