import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { rhythm } from '../utils/typography';
import { BioQuery } from '../../types/graphql-types';
import Social from './social';

const BioContainer = styled.div`
  display: flex;
  margin: ${rhythm(1)};
  text-align: center;
  font-size: ${rhythm(1 / 2)};
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
`;

const AvatorContainer = styled.div`
  margin-right: ${rhythm(1 / 2)};
  margin-bottom: 0;
  min-width: 50;
  border-radius: 100%;
`;

const Bio: React.FC = () => {
  const data = useStaticQuery<BioQuery>(bioQuery);

  const { author } = data.site.siteMetadata;
  return (
    <BioContainer>
      <AvatorContainer>
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author.name}
          imgStyle={{
            borderRadius: '50%',
          }}
        />
      </AvatorContainer>
      <p>
        Written by <strong>{author.name}</strong>.
        <Social />
      </p>
    </BioContainer>
  );
};

export default Bio;

const bioQuery = graphql`
  query Bio {
    avatar: file(relativePath: { eq: "images/profile.jpg" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author {
          name
        }
      }
    }
  }
`;
